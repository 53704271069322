.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.2s;
}
.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}
.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.vab-content {
  min-height: calc(100vh - 65px - 20px - 20px) !important;
  margin: 20px;
}
.vab-content .error-container {
  height: calc(100vh - 65px - 30px - 30px - 30px - 30px - 30px - 20px) !important;
}
.container {
  height: 100%;
  padding: 30px;
  background: #fff;
  border-radius: 5px;
}
