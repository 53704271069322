.vab-layout-wrap .vab-sider {
  position: fixed;
  left: 0;
  height: 100vh;
  overflow: auto;
}
.vab-layout-wrap .vab-sider .vab-menu {
  height: calc(100vh -110px);
}
.vab-layout-wrap .vab-layout {
  padding-left: 208px;
  transition: all 0.2s;
}
.vab-layout-wrap .vab-mobile-layout {
  padding-left: 0;
  transition: all 0.2s;
}
.vab-layout-wrap .vab-collapse .vab-logo .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
  transition: all 0.2s;
}
.vab-layout-wrap .vab-collapse + .vab-layout {
  padding-left: 81px;
  transition: all 0.2s;
}
.vab-layout-wrap .vab-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #000;
  opacity: 0.5;
}
.vab-layout-wrap .vab-mobile {
  position: fixed !important;
  z-index: 999;
}
.vab-layout-wrap .vab-mobile.vab-collapse {
  width: 0 !important;
  min-width: 0 !important;
  max-width: 0 !important;
}
.vab-layout-wrap .vab-mobile.vab-collapse * {
  display: none !important;
  width: 0 !important;
  min-width: 0 !important;
  max-width: 0 !important;
}
.vab-layout-wrap .vab-mobile.vab-collapse .ant-menu-item,
.vab-layout-wrap .vab-mobile.vab-collapse .ant-menu-submenu {
  display: none !important;
  width: 0 !important;
  min-width: 0 !important;
  max-width: 0 !important;
}
.vab-layout-wrap .vab-mobile.vab-collapse + .vab-layout {
  padding-left: 0px !important;
  transition: all 0.2s;
}
.vab-layout-wrap .vab-header {
  padding: 0;
  background: #fff;
}
.vab-layout-wrap .vab-header .ant-col + .ant-col {
  display: flex;
  justify-content: flex-end;
  padding: 0 30px;
}
.vab-layout-wrap .vab-header .trigger {
  height: 65px;
  padding: 0 30px;
  font-size: 18px;
  line-height: 65px;
  cursor: pointer;
  transition: color 0.3s;
}
.vab-layout-wrap .vab-header .trigger:hover {
  color: #1890ff;
}
.vab-layout-wrap .ant-layout-sider {
  background: #030021;
}
.vab-layout-wrap .ant-menu.ant-menu-dark,
.vab-layout-wrap .ant-menu-dark .ant-menu-sub,
.vab-layout-wrap .ant-menu.ant-menu-dark .ant-menu-sub {
  color: #fff;
  background: #030021;
}
.vab-layout-wrap .ant-menu.ant-menu-dark .ant-menu-title-content,
.vab-layout-wrap .ant-menu-dark .ant-menu-sub .ant-menu-title-content,
.vab-layout-wrap .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-title-content {
  padding: 0 8px;
  margin: 0 25px 0 5px;
  border-radius: 8px;
}
.vab-layout-wrap .ant-menu-dark .ant-menu-item,
.vab-layout-wrap .ant-menu-dark .ant-menu-item-group-title,
.vab-layout-wrap .ant-menu-dark .ant-menu-item > a,
.vab-layout-wrap .ant-menu-dark .ant-menu-item > span > a {
  color: #fff;
}
.vab-layout-wrap .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #030021;
}
.vab-layout-wrap .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected .ant-menu-title-content {
  background: #2C2945;
}
.vab-layout-wrap .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected::before {
  position: absolute;
  top: 7px;
  left: 0;
  content: '';
  width: 5px;
  height: 25px;
  border-radius: 0 4px 4px 0;
  background: #4921D1;
}
.vab-layout-wrap .ant-menu-title-content {
  display: flex;
  align-items: center;
}
