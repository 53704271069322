
  .vab-logo {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #fff;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    padding-top: 5px;
    margin-bottom: 25px;

    img {
      width: 40px;
      height: 40px;
    }
  }

@vab-color-blue: #1890ff;@vab-margin: 20px;@vab-padding: 30px;@vab-header-height: 65px;