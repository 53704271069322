@import "./normalize.less";

html {
  body {

    * {
      box-sizing: border-box;
    }

    /* ant-input-search搜索框 */
    .ant-input-search {
      max-width: 250px;
    }

    /* ant-pagination分页 */
    .ant-pagination {
      margin-top: @vab-margin;
      text-align: center;

      &.ant-table-pagination {
        float: none !important;
        margin-top: @vab-margin;
      }
    }

    .ant-table-thead>tr>th {
      background: #FAFBFC;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 14px;
      color: #4D6086;
    }

    .ant-table-tbody>tr>td {
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 14px;
      color: #555770;
    }

    .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }

    .ant-modal-wrap {
      display: flex;
      justify-content: center;
      align-items: center;

      .ant-modal {
        top: 0;

        .ant-modal-body {
          padding: 30px 25px 25px;
          max-height: 70vh !important;
          overflow-y: auto;
        }

        .ant-modal-footer {
          padding: 5px 0 30px;
          border-top: none;
          text-align: center !important;

          .ant-btn {
            width: 74px;
            height: 32px;
          }
        }
      }
    }
  }
}