.ant-avatar {
  margin-right: 5px;
}
.vab-avatar .ant-dropdown-link {
  display: block;
  min-height: 64px;
  cursor: pointer;
}
.ant-form-item-label {
  width: 115px;
}
.ant-input-password {
  width: 295px;
}
:deep .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  font-size: 10px !important;
  transform: translateY(-3px) !important;
}
.ant-form-item {
  margin-bottom: 20px !important;
}
.ant-form-item:nth-last-child(1) {
  margin-bottom: 0 !important;
}
