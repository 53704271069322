
  .vab-layout-wrap {
    .vab-sider {
      position: fixed;
      left: 0;
      height: 100vh;
      overflow: auto;

      .vab-menu {
        height: calc(100vh -110px);
      }
    }

    .vab-layout {
      padding-left: 208px;
      transition: all 0.2s;
    }

    .vab-mobile-layout {
      padding-left: 0;
      transition: all 0.2s;
    }

    .vab-collapse {
      .vab-logo .anticon+span {
        display: inline-block;
        max-width: 0;
        opacity: 0;
        transition: all 0.2s;
      }

      &+.vab-layout {
        padding-left: 81px;
        transition: all 0.2s;
      }
    }

    .vab-mask {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 998;
      width: 100%;
      height: 100vh;
      overflow: hidden;
      background: #000;
      opacity: 0.5;
    }

    .vab-mobile {
      position: fixed !important;
      z-index: 999;

      &.vab-collapse {
        width: 0 !important;
        min-width: 0 !important;
        max-width: 0 !important;

        * {
          display: none !important;
          width: 0 !important;
          min-width: 0 !important;
          max-width: 0 !important;
        }

        .ant-menu-item,
        .ant-menu-submenu {
          display: none !important;
          width: 0 !important;
          min-width: 0 !important;
          max-width: 0 !important;
        }

        &+.vab-layout {
          padding-left: 0px !important;
          transition: all 0.2s;
        }
      }
    }

    .vab-header {
      padding: 0;
      background: #fff;

      .ant-col+.ant-col {
        display: flex;
        justify-content: flex-end;
        padding: 0 @vab-padding;
      }

      .trigger {
        height: @vab-header-height;
        padding: 0 @vab-padding;
        font-size: 18px;
        line-height: @vab-header-height;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: #1890ff;
        }
      }
    }

    .ant-layout-sider {
      background: #030021;

    }

    .ant-menu.ant-menu-dark,
    .ant-menu-dark .ant-menu-sub,
    .ant-menu.ant-menu-dark .ant-menu-sub {
      color: #fff;
      background: #030021;

      .ant-menu-title-content {
        padding: 0 8px;
        margin: 0 25px 0 5px;
        border-radius: 8px;
      }
    }

    .ant-menu-dark .ant-menu-item,
    .ant-menu-dark .ant-menu-item-group-title,
    .ant-menu-dark .ant-menu-item>a,
    .ant-menu-dark .ant-menu-item>span>a {
      color: #fff;
    }

    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background: #030021;

      .ant-menu-title-content {
        background: #2C2945;
      }

      &::before {
        position: absolute;
        top: 7px;
        left: 0;
        content: '';
        width: 5px;
        height: 25px;
        border-radius: 0 4px 4px 0;
        background: #4921D1;
      }
    }

    .ant-menu-title-content {
      display: flex;
      align-items: center;
    }
  }

@vab-color-blue: #1890ff;@vab-margin: 20px;@vab-padding: 30px;@vab-header-height: 65px;